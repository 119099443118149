$(document).ready(function() {
    $("div.mm-tab-menu>div.list-group>a").click(function(e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        $(this).parents('.mm-tab-menu').siblings('.mm-tab').children('.mm-tab-content').removeClass("active");
        $(this).parents('.mm-tab-menu').siblings('.mm-tab').children('.mm-tab-content').eq(index).addClass("active");
    });
});

// $(window).load(function(){
//     $('#maintenanceModal').modal('show');
// });

jQuery(window).load(function() {
    $(".jq-tabs").tabs();
});

var revapi1, tpj;
( function() {
    if (!/loaded|interactive|complete/.test(document.readyState))
        document.addEventListener("DOMContentLoaded", onLoad);
    else
        onLoad();

    function onLoad() {
        if (tpj === undefined) {
            tpj = jQuery;
            if ("off" == "on")
                tpj.noConflict();
        }

        if (tpj("#rev_slider_1_1").revolution == undefined) {
            revslider_showDoubleJqueryError("#rev_slider_1_1");
        } else {
            revapi1 = tpj("#rev_slider_1_1").show().revolution({
                sliderType : "standard",
                sliderLayout : "auto",
                dottedOverlay : "none",
                delay : 9000,
                navigation : {
                    onHoverStop : "off",
                },
                visibilityLevels : [1240, 1024, 778, 480],
                gridwidth : 1240,
                gridheight : 800,
                lazyType : "none",
                shadow : 0,
                spinner : "spinner2",
                stopLoop : "off",
                stopAfterLoops : -1,
                stopAtSlide : -1,
                shuffle : "off",
                autoHeight : "off",
                disableProgressBar : "on",
                hideThumbsOnMobile : "off",
                hideSliderAtLimit : 0,
                hideCaptionAtLimit : 0,
                hideAllCaptionAtLilmit : 0,
                debugMode : false,
                fallbacks : {
                    simplifyAll : "off",
                    nextSlideOnWindowFocus : "off",
                    disableFocusListener : false,
                }
            });
        }
    }
}());
